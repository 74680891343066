import i18nInit from 'i18n/i18n'

const Modules = [
  { selectorName: 'CropOverview', selector: '.js--cropoverview', importModule: () => import('./components/Kundeportal/CropOverviewApp') },
  { selectorName: 'CropOverviewCropOverlay', selector: '.js--cropoverview-cropoverlay', importModule: () => import('./components/Kundeportal/CropOverlayApp') },
  { selectorName: 'MarketCommentsOverview', selector:  '.js--MarketCommentsOverview',  importModule: ()=> import( './components/Kundeportal/CommentsFeed/MarketCommentsFeed' )},
  { selectorName: 'StockStatus', selector: '.js--stockstatus', importModule: () => import('./components/Kundeportal/StockStatusAndOrderButtonApp') },
  { selectorName: 'MarketCommentsFeedWidgetOverview', selector: '.js--MarketCommentsWidget', importModule: () => import('./components/Kundeportal/CommentsFeed/MarketCommentsWidget')},

  { selectorName: 'GenericBoxApp', selector: '.js--GenericBox', importModule: () => import('./components/Common/Ui/GenericBoxApp')},
  
  { selectorName: 'BasketClearedOverlayApp', selector: '.js--basketClearedOverlay', importModule: () => import('./components/Kundeportal/Basket/BasketClearedOverlayApp') },

  { selectorName: 'ProfileDeleteAppAccount', selector: '.js--ProfileDeleteAppAccount', importModule: () => import('./components/Kundeportal/DialogPortal/ProfileDeleteAppAccountApp/Index')},

  { selectorName: 'MembershipDataApp', selector: '.js--MembershipDataApp', importModule: () => import('./components/Kundeportal/MembershipDataApp')},

  //Partnership
  { selectorName: 'PartnershipCropOverview', selector: '.js--PartnershipCropOverview',  importModule: ()=> import('./components/Kundeportal/PartnershipContracts/CropOverview' )},
  { selectorName: 'PartnershipSalesContractsOverview', selector:  '.js--PartnershipSalesContractsOverview',  importModule: ()=> import('./components/Kundeportal/PartnershipContracts/SalesContractsOverview' )},
  { selectorName: 'CommunicationFeedOverview', selector:  '.js--CommunicationFeedOverview',  importModule: ()=> import('./components/Kundeportal/CommentsFeed/CommunicationFeed' )},
  { selectorName: 'CommunicationFeedWidgetOverviewRendering', selector: '.js--CommunicationFeedWidget', importModule: () => import('./components/Kundeportal/CommentsFeed/CommunicationFeedWidget')},

  { selectorName: 'MarketingPermissionOverview', selector: '.js--ProfileMarketingPermission', importModule: () => import('./components/Kundeportal/DialogPortal/ProfileMarketingPermission')},
  //merx
  { selectorName: 'MerxFAQPopUp', selector: '.js--MerxFAQPopUp', importModule: () => import('./components/Kundeportal/Merx/FAQPopUp')},
  { selectorName: 'Merx', selector: '.js--merx', importModule: () => import('./components/Kundeportal/Merx/MerxOverviewPage') },
  { selectorName: 'MerxGraphPage', selector: '.js--merxGraph', importModule: () => import('./components/Kundeportal/Merx/MerxGraphPage') },
  { selectorName: 'MerxGraphPageHeader', selector: '.js--merxGraphHeader', importModule: () => import('./components/Kundeportal/Merx/MerxGraphPageHeader') },

  //agrar
  { selectorName: 'AccountOverviewApp', selector: '.js--hage-accountoverview', importModule: () => import('./components/Agrar/AccountOverview/AccountOverviewApp') }

]

let intizlizedNS = false;

const DomFactory = () => {
  if(intizlizedNS){
    i18nInit();
     intizlizedNS = true;
  }

  const modules: { [key: string]: any } = {}

  for (let i = 0; i < Modules.length; i++) {
    let nodes: any[] = []
    let tags = document.querySelectorAll(Modules[i].selector)
    for (let x = 0; x < tags.length; x++) {
      Modules[i].importModule().then(module => {
        module.default(tags[x])
        nodes.push(module)
      })
    }
    if (nodes.length) modules[Modules[i].selectorName] = nodes
  }
}

//   window.modules = DomFactory.modules
export default DomFactory;