import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import { WebApiV1 } from "../data-access/WebApiProxy/WebApiProxy";
import { getHost } from 'utils/HostHelper';
import axios from 'axios';
//import LanguageDetector from 'i18next-browser-languagedetector';
// don't want to use this?
// have a look at the Quick start guide 
// for passing in lng and translations on init

const axiosInstance = axios.create();

const client = new WebApiV1.DictionaryClient(getHost(), axiosInstance);

export default function i18nInit(language = document.documentElement.lang) {
  return new Promise((resolve: any, reject) => {
    i18n
      // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
      // learn more: https://github.com/i18next/i18next-http-backend
      // want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn
      .use(Backend)
      // detect user language
      // learn more: https://github.com/i18next/i18next-browser-languageDetector
      //.use(LanguageDetector)
      // pass the i18n instance to react-i18next.
      .use(initReactI18next)
      // init i18next
      // for all options read: https://www.i18next.com/overview/configuration-options
      .init({
        lng: language,
        fallbackLng: 'da',
        debug: true,
        pluralSeparator:'_',
        interpolation: {
          escapeValue: false, // not needed for react as it escapes by default
        },
        backend: {
          request: (options, url, payload, callback) => {
            client.getLanguageSpecificDictionary(language, "v1").then((data: string) => {
              callback("", { "status": 200, data: data })
            });
          }
        },
        react: {
          useSuspense: true
        }
      }, (error) => {
        if (error) reject(error);

        resolve();
      });
  })
};