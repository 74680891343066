import DomFactory from './domFactory'
import reportWebVitals from './reportWebVitals';

declare global {
    interface Window { DomFactory: any; }
}


const Initialize = () => {
    DomFactory();
    // If you want to start measuring performance in your app, pass a function
    // to log results (for example: reportWebVitals(console.log))
    // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
    reportWebVitals();
}
Initialize()


window.DomFactory = () =>  DomFactory();

